<template>
  <div class="error-layout" v-bar>
    <div class="inner">
      <router-view class="indent" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'error'
}
</script>

<style lang="sass">
.error-layout
  height: 100vh
  .inner
    padding: 0 15px 0 0
</style>