<template>

  <div class="home indent">

    <v-drum :isStart="isStart" :restPoints="restPoints" />

    <div class="buttons">
      <div class="buttons_inner">
        <v-button @click="choose" title="Выбрать" icon="bi bi-check2-circle" />
        <v-button @click="reset" title="Обновить" icon="bi bi-arrow-counterclockwise" />
        <v-button v-if="false" @click="backup" title="Восстановить" icon="bi bi-arrow-repeat" :loading="loadingFromBackup" />
        <v-button v-if="false" @click="backupFromVuex" title="Backup from Vuex" icon="bi bi-file-earmark-bar-graph" :loading="loadingFromVuex" />
        <v-button v-if="true" title="Magic" @click="magic" />
      </div>
    </div>

    <v-points :list="lists[currentList]" :scrollId="scrollId" />

    <v-pointsTabs />

  </div>

</template>

<script>
import pointsTabs from "../components/home/pointsTabs";
import drum from "../components/home/drum";
import points from "../components/home/points";
import button from "../components/common/button";
import {mapGetters, mapMutations} from 'vuex';
import axios from "axios";
import consts from "../consts";
import backConsts from "../../server/consts";

export default {
  name: 'Home',
  data: () => ({
    restPoints: null,
    scrollId: null,
    isStart: true,
    loadingFromVuex: false,
    loadingFromBackup: false
  }),
  components: {
    'v-button': button,
    'v-points': points,
    'v-drum': drum,
    'v-pointsTabs': pointsTabs
  },
  computed: {
    ...mapGetters(['GET_LISTS', 'GET_CURRENT_LIST']),
    backupPoints() {
      return this.GET_BACKUP
    },
    lists() {
      return this.GET_LISTS
    },
    currentList() {
      return this.GET_CURRENT_LIST
    },
    points() {
      return this.lists[this.currentList].points
    }
  },
  methods: {
    async magic() {
      // let res = await axios.get(`${consts.baseURL}/api/pointsTenderness`, {params: {key: backConsts.key}})
      // let test = {id: 790, title: 'Test point', checked: false, active: false}
      // console.log(res.data)
      // const load = {
      //   id: 3,
      //   title: 'Нежные',
      //   checked: false,
      //   active: false,
      //   points: [...res.data]
      // }
      // const toDelete = {
      //   id: 1,
      //   pointId: 790
      // }
      // const toUpdate = {
      //   id: 1,
      //   pointId: 790,
      //   title: 'Updated title'
      // }
      // await axios.post(`${consts.baseURL}/api/lists/addList`, load, {params: {key: backConsts.key}})
      // let res = await axios.put(`${consts.baseURL}/api/lists/updateListName`, load, {params: {key: backConsts.key}})
      // let res = await axios.delete(`${consts.baseURL}/api/lists/deleteList`, {data: load, params: {key: backConsts.key}})
      // let res = await axios.get(`${consts.baseURL}/api/lists`, {params: {key: backConsts.key}})

      // let zxc = await axios.post(`${consts.baseURL}/api/lists/addPoint`, load, {params: {key: backConsts.key}})
      // let zxc = await axios.delete(`${consts.baseURL}/api/lists/deletePoint`, {data: toDelete, params: {key: backConsts.key}})
      // let zxc = await axios.put(`${consts.baseURL}/api/lists/updatePoint`, toUpdate, {params: {key: backConsts.key}})

      // console.log(zxc.data)
      // console.log('Magic')
    },
    ...mapMutations(['BACKUP']),
    getRandomId() {
      let unChecked = this.points.filter((item) => !item.checked)
      let min = 1
      let max = unChecked.length
      let rand = Math.floor(min + Math.random() * (max + 1 - min))
      return unChecked.length > 0 ? unChecked[rand - 1].id : 0
    },
    async choose() {
      let randId = this.getRandomId()
      this.restPoints -= 1
      if(this.restPoints >= 0) {
        this.isStart = false
        await this.$root.$emit('animationDrum', randId)

        this.scrollId = randId
        this.points.forEach(item => item.active = false)
        let item = this.points.find(item => item.id === randId ? item : false)
        item ? item.checked = true : false
        item ? item.active = true : false
      } else this.reset()

    },
    reset() {
      if(this.points) {
        this.isStart = true
        this.points.forEach(item => {
          item.checked = false
          item.active = false
        })
        this.restPoints = this.points.length
        this.scrollId = 0
      }
    },
    async backup() {
      this.loadingFromBackup = true
      const backup = await axios.get(`${consts.baseURL}/api/backup`, {params: {key: backConsts.key}})
      await axios.delete(`${consts.baseURL}/api/pointsMain/clean`, {params: {key: backConsts.key}})
      await axios.post(`${consts.baseURL}/api/pointsMain/add`, backup.data, {params: {key: backConsts.key}})
      await this.BACKUP(backup.data)
      this.loadingFromBackup = false
      this.restPoints = this.points.length
    },
    async backupFromVuex() {
      this.loadingFromVuex = true
      await axios.delete(`${consts.baseURL}/api/backup/clean`, {params: {key: backConsts.key}})
      await axios.post(`${consts.baseURL}/api/backup/add`, this.backupPoints, {params: {key: backConsts.key}})
      this.loadingFromVuex = false
    }
  },
  mounted() {
    this.restPoints = this.points.length
    this.$root.$on('reset', () => this.reset())
    this.$root.$on('addPoint', () => this.restPoints++)
    this.$root.$on('removePoint', () => this.restPoints--)
  }
}
</script>

<style lang="sass">
.home
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: repeat(3, min-content)
  grid-column-gap: 50px
  grid-row-gap: 10px
  justify-items: left
  align-items: start
  .drum
    margin-bottom: 25px
    grid-column: 1/2
    grid-row: 1/2
  .buttons
    display: grid
    justify-content: center
    margin-bottom: 10px
    grid-column: 1/2
    grid-row: 2/3
    &_inner
      width: 150px
      display: grid
      grid-template-rows: 1fr
      grid-gap: 10px
  .points
    width: 500px
    height: 350px
    grid-column: 2/3
    grid-row: 1/3
  .pointsTabs
    grid-column: 2/3
    grid-row: 3/4

@media screen and (max-width: 992px)
  .home
    grid-template-columns: 1fr
    grid-template-rows: repeat(4, min-content)
    justify-items: center
    .drum
      grid-column: 1/2
      grid-row: 1/2
    .buttons
      grid-column: 1/2
      grid-row: 2/3
      margin-bottom: 25px
    .points
      grid-column: 1/2
      grid-row: 3/4
    .pointsTabs
      grid-column: 1/2
      grid-row: 4/5
      justify-content: center

@media screen and (max-width: 600px)
  .home
    &.indent
      padding-left: 15px
      padding-right: 15px

@media screen and (max-width: 576px)
  .home
    .points
      width: 100%
</style>