<template>
  <div class="inner-layout">

    <div class="bg"></div>
    <div class="help"></div>

    <v-notification />
    <v-modal />

    <div class="inner" v-bar>
      <div class="wrap">
        <div class="top indent">

          <v-mobile-menu
            :isShow="isMobileMenu"
            @closeMobileMenu="isMobileMenu = false"
            :links="links"
          />

          <svg class="ham hamRotate" :class="{active: isMobileMenu}" viewBox="0 0 100 100" width="40" @click="mobileMenu">
            <path
              class="line top"
              d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
            <path
              class="line"
              d="m 70,50 h -40" />
            <path
              class="line bottom"
              d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
          </svg>

         <v-logo class="title" @click.native="toHome" />

          <div class="exit" @click="logout" v-if="isExit">
            <span v-if="user">{{ user.name }}</span><i class="bi bi-box-arrow-right" @click="logout"></i>
          </div>
        </div>
        <div class="links not-mobile">
          <router-link
            v-for="(link, index) in links"
            :key="index"
            :to="{name: link.route}"
            exact
          >
            {{ link.name }}
          </router-link>
        </div>

        <div v-if="isLoading" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <router-view v-else />

      </div>
    </div>

  </div> <!-- inner-layout -->
</template>

<script>
import router from "@/router";
import button from "@/components/common/button";
import notification from "@/components/common/notification";
import modal from "../components/common/modal";
import mobileMenu from "../components/common/mobileMenu";
import axios from "axios";
import consts from '../consts/index';
import {mapMutations} from 'vuex';
import backConsts from '../../server/consts/index';
import logo from "../components/common/logo";

export default {
  name: 'inner',
  components: {
    'v-button': button,
    'v-notification': notification,
    'v-modal': modal,
    'v-mobile-menu': mobileMenu,
    'v-logo': logo
  },
  data: () => ({
    isLoading: false,
    isExit: true,
    user: null,
    isMobileMenu: false,
    links: [
      {name: 'Главная', route: 'Home'}
    ]
  }),
  methods: {
    ...mapMutations(['SET_LISTS']),
    logout() {
      if (this.$route.path !== '/login') {
        router.push({name: 'Login'})
        this.$root.$emit('reset')
        localStorage.clear()
      }
    },
    mobileMenu() {
      this.isMobileMenu = !this.isMobileMenu
    },
    toHome() {
      if(this.$route.path !== '/') this.$router.push({name: 'Home'})
    },
    async loadData() {
      this.isLoading = true
      let lists = await axios.get(`${consts.baseURL}/api/lists`, {params: {key: backConsts.key}})
      await this.SET_LISTS(lists.data)
      this.isLoading = false
    }
  },
  async created() {
    await this.loadData()
    this.user = JSON.parse(localStorage.getItem('user'))
    this.$root.$on('hideExit', () => this.isExit = false)
    this.$root.$on('showExit', () => this.isExit = true)
  }
}
</script>

<style lang="sass">
.inner-layout
  position: relative
  .spinner-border
    display: block
    margin: 0 auto
  .bg
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: url("../assets/img/bg.jpg") no-repeat center center
    background-size: cover
    z-index: -1
  .help
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: rgba(255, 255, 255, 0.5)
    z-index: -1
  .inner
    position: relative
    z-index: 0
    height: 100vh
    .wrap
      padding: 20px 0 !important
  .top
    position: relative
    margin: 0 0 20px
    .ham
      position: absolute
      z-index: 20
      top: 50%
      transform: translateY(-50%)
      left: 4px
      cursor: pointer
      -webkit-tap-highlight-color: transparent
      transition: transform 400ms
      -moz-user-select: none
      -webkit-user-select: none
      -ms-user-select: none
      user-select: none
      display: none
    .hamRotate.active
      transform: translateY(-50%) rotate(45deg)
      .line
        stroke: $mainLight
    .line
      fill: none
      transition: stroke-dasharray 400ms, stroke-dashoffset 400ms
      stroke: #000
      stroke-width: 3.5
      stroke-linecap: round
    .ham .top
      stroke-dasharray: 40 121
    .ham .bottom
      stroke-dasharray: 40 121
    .ham.active .top
      stroke-dashoffset: -68px
    .ham.active .bottom
      stroke-dashoffset: -68px
    .title
      //text-align: center
      //color: $main
      //font-size: 25px
      cursor: pointer
      width: 100px
      height: auto
      margin: 0 auto
      display: block
      //margin: 0 0 20px
    .exit
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 15px
      cursor: pointer
      display: grid
      grid-template-columns: repeat(2, min-content)
      grid-gap: 4px
      align-items: center
      &:hover
        color: $mainHover
      span
        font-size: 14px
        color: $main
        font-weight: bold
      i
        font-size: 18px
        position: relative
        top: 1px
        color: $main
  .links.not-mobile
    display: grid
    grid-template-columns: repeat(2, min-content)
    grid-gap: 10px
    justify-content: center
    margin: 0 0 40px
    a
      text-decoration: none
      color: $grey
      &.router-link-exact-active
        color: $main
        text-decoration: underline

@media screen and (max-width: 540px)
  .inner-layout
    .top
      margin-bottom: 40px
      .ham
        display: block
    .links.not-mobile
      display: none
</style>