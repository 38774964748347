<template>
  <svg class="mojito" width="329" height="470" viewBox="0 0 329 470" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="73" cy="85" r="73" fill="#303A11"/>
    <path d="M146 85C146 125.317 113.317 158 73 158C32.6832 158 0 125.317 0 85C0 44.6832 32.6832 12 73 12C113.317 12 146 44.6832 146 85ZM10.9758 85C10.9758 119.255 38.745 147.024 73 147.024C107.255 147.024 135.024 119.255 135.024 85C135.024 50.745 107.255 22.9758 73 22.9758C38.745 22.9758 10.9758 50.745 10.9758 85Z" fill="#5EAE34"/>
    <path d="M127.028 85C127.028 75.9246 124.742 66.9954 120.381 59.0366C116.02 51.0778 109.724 44.3458 102.075 39.462L78.8149 75.8924C80.3448 76.8692 81.6039 78.2156 82.4762 79.8073C83.3484 81.3991 83.8056 83.1849 83.8056 85H127.028Z" fill="#A9E22F"/>
    <path d="M102.343 130.365C108.68 126.267 114.088 120.886 118.218 114.57C122.349 108.253 125.11 101.142 126.324 93.6931L83.6649 86.7386C83.422 88.2283 82.8697 89.6506 82.0437 90.9139C81.2176 92.1772 80.136 93.2533 78.8686 94.0731L102.343 130.365Z" fill="#A9E22F"/>
    <path d="M42.9049 129.87C50.2813 134.818 58.7636 137.87 67.6011 138.758C76.4386 139.645 85.3585 138.34 93.5714 134.959L77.1143 94.9917C75.4717 95.6681 73.6877 95.9291 71.9202 95.7516C70.1527 95.574 68.4562 94.9635 66.981 93.974L42.9049 129.87Z" fill="#A9E22F"/>
    <path d="M19.6549 93.5644C21.5319 105.256 27.1977 116.007 35.781 124.164L65.5562 92.8327C63.8395 91.2013 62.7064 89.0511 62.331 86.7129L19.6549 93.5644Z" fill="#A9E22F"/>
    <path d="M43.316 39.8568C35.8947 44.7366 29.7909 51.3709 25.545 59.1722C21.2991 66.9735 19.0419 75.7013 18.9734 84.5829L62.1947 84.9166C62.2084 83.1402 62.6598 81.3947 63.509 79.8344C64.3582 78.2742 65.5789 76.9473 67.0632 75.9713L43.316 39.8568Z" fill="#A9E22F"/>
    <path d="M91.775 34.3388C78.3388 29.3594 63.4749 29.9214 50.4531 35.9012L68.4906 75.1802C71.095 73.9843 74.0678 73.8719 76.755 74.8678L91.775 34.3388Z" fill="#A9E22F"/>
    <path d="M249.5 43.9341L189 221.934L203 224.434C222.167 167.767 260 55.4341 262.5 48.9341C265.5 42.4341 317 17.4341 324 12.9341C327.5 10.4341 329 8.93413 328 6.43413C327.5 5.43414 326.2 3.53413 325 1.93413C323.8 0.334132 320.5 1.1008 319 1.93413C299.5 12.7675 259.6 34.8341 256 36.4341C252.4 38.0341 250.167 42.1008 249.5 43.9341Z" fill="#E4E4E4" stroke="black"/>
    <rect x="160" y="373" width="21" height="76" fill="black"/>
    <path d="M109 463C109 455.268 115.268 449 123 449H217C224.732 449 231 455.268 231 463V463C231 466.866 227.866 470 224 470H116C112.134 470 109 466.866 109 463V463Z" fill="black"/>
    <path d="M172 406.5C172.104 396.317 172 380.5 174 380.5C176 380.5 176.37 396.3 176.5 406.5C176.649 418.255 176 436.5 174 436.5C172 436.5 171.88 418.241 172 406.5Z" fill="white"/>
    <path d="M271 212.5H69C36.0001 301.5 87.5001 374.5 173 374.5C249 374.5 304.5 299.5 271 212.5Z" fill="#ED143D"/>
    <path d="M84 172H257L259 184H81L84 172Z" fill="#ED143D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M69 212.5C91 162 85 116.5 70.5 83.5H269.5C249.5 131 253 174 271.5 212.5H69ZM73 212C94.1852 162.674 88.4074 118.233 74.4444 86H266.074C246.815 132.395 250.185 174.395 268 212H73Z" fill="#ED143D"/>
    <path d="M102.5 308.5C122 336 148 352.5 148 352.5C148 352.5 115 344 95 313.5C75 283 77 252.5 77 252.5C77 252.5 83 281 102.5 308.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'GlassOfMojito'
}
</script>

<style lang="sass">

</style>