<template>
  <div class="points">
    <div class="mini-title">
      <div class="mini-title-text">
        <span
          :style="{background: colors[list.id - 1]}"
        >
          {{ list.title }}
        </span>
      </div>
      <i class="add bi bi-plus" title="Добавить пункт" @click="addPoint()"></i>
    </div>
    <div v-bar>
      <div class="inner" ref="pointsInner">
        <div class="point"
             :class="{'checked': point.checked, 'active': point.active}"
             v-for="(point, index) in list.points"
             :key="index"
             :ref="`point_${point.id}`"
        >
          <div class="name">
            {{ point.id }}. {{ point.title }}
          </div>
          <i class="edit bi bi-pencil" title="Редактировать" @click="editPoint(point)"></i>
          <i class="delete bi bi-trash" title="Удалить" @click="deletePoint(point)"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Points',
  data() {
    return {

    }
  },
  props: {
    list: {
      type: Object,
      required: true
    },
    scrollId: {
      type: Number
    }
  },
  computed: {
    ...mapGetters(['GET_CURRENT_LIST', 'GET_COLORS']),
    currentList() {
      return this.GET_CURRENT_LIST
    },
    colors() {
      return this.GET_COLORS
    }
  },
  methods: {
    async addPoint() {
      this.$root.$emit('show-modal', {mode: 'create'})
    },
    async editPoint(point) {
      this.$root.$emit('show-modal', {point, mode: 'edit'})
    },
    async deletePoint(point) {
      this.$root.$emit('show-modal', {point, mode: 'delete'})
    },
    scrollToPoint(id) {
      let scrolled = this.$refs.pointsInner.scrollTop
      if(id) {
        let activeRef = 'point_' + id
        let pointPosition = this.$refs[activeRef][0].offsetTop
        this.$refs.pointsInner.scrollBy({
          top: pointPosition - scrolled,
          behavior: 'smooth'
        })
      } else {
        this.$refs.pointsInner.scrollBy({
          top: 0 - scrolled,
          behavior: 'smooth'
        })
      }
    }
  },
  watch: {
    scrollId: {
      handler(newVal, oldVal) {
        this.scrollToPoint(newVal)
      }
    }
  }
}
</script>

<style lang="sass">
.points
  border: 1px solid $grey
  border-radius: 10px
  padding: 15px
  display: grid
  grid-template-rows: min-content 1fr
  grid-gap: 20px
  user-select: none
  .mini-title
    display: grid
    grid-template-columns: 1fr min-content
    &-text
      //background: rgba($grey, .6)
      //padding: 2px 6px
      //color: $mainLight
      //border-radius: 3px
      span
        //font-weight: bold
        //text-decoration: underline
        color: $mainLight
        //background: $save
        border-radius: 3px
        padding: 2px 8px
        display: inline-block
        &.pointsMain_
          background: rgba($save, 1)
          //border-bottom: 2px solid $save
        &.pointsHot_
          background: rgba($hot, 1)
          //border-bottom: 2px solid $create
        &.pointsTenderness_
          background: $tenderness
    .add
      font-size: 28px
      color: $grey
      display: grid
      justify-content: end
      padding-right: 6px
      cursor: pointer
  .inner
    padding-right: 12px !important
    .point
      display: grid
      grid-template-columns: 1fr repeat(2, min-content)
      grid-gap: 10px
      align-items: center
      margin-bottom: 4px
      &.checked
        .name
          border-radius: 3px
          background: $main
          color: $mainLight
      .name
        padding: 5px
      .edit
        cursor: pointer
      .delete
        cursor: pointer
      &.active
        .name
          background: $create
          color: $mainLight
</style>