<template>
  <textarea
    :value="value"
    :placeholder="placeholder"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: {},
    placeholder: {
      type: String,
      default: 'Описание'
    }
  }
}
</script>

<style lang="sass">
textarea
  padding: 5px 10px
  font-family: sans-serif
  font-size: 14px
  color: $grey
  resize: none
  border: 1px solid $grey
  background: rgba($mainLight, .05)
  &::placeholder
    font-size: 15px
    font-family: sans-serif
  &:hover, &:focus
    border: 1px solid $mainHover
  &:disabled
    border-color: rgba($grey, 1)
  &::placeholder
    color: $grey
</style>