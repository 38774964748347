<template>
  <div class="login-layout" v-bar>
    <div class="inner">
      <router-view class="indent" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'login'
}
</script>

<style lang="sass">
.login-layout
  height: 100vh
</style>