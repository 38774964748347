export default {
  state: {
    lists: [],
    currentList: 0,
    colors: [
      `rgb(9,158,204)`,
      `rgb(237,131,40)`,
      `rgb(211,70,253)`
    ],
    backupPoints: [
      {
        id: 1,
        title: 'Взять на руки',
        checked: false,
        active: false
      },
      {
        id: 2,
        title: 'Танец под "Black black heart"',
        checked: false,
        active: false
      },
      {
        id: 3,
        title: 'Крутить попой. Бачата',
        checked: false,
        active: false
      },
      {
        id: 4,
        title: 'Сделать совместные фотки в кровати',
        checked: false,
        active: false
      },
      {
        id: 5,
        title: 'Обнять и поваляться',
        checked: false,
        active: false
      },
      {
        id: 6,
        title: 'Взять за попу',
        checked: false,
        active: false
      },
      {
        id: 7,
        title: 'Посадить на колени',
        checked: false,
        active: false
      },
      {
        id: 8,
        title: 'Подложить руки под попу',
        checked: false,
        active: false
      },
      {
        id: 9,
        title: 'Обнять',
        checked: false,
        active: false
      },
      {
        id: 10,
        title: 'Поцеловать',
        checked: false,
        active: false
      },
      {
        id: 11,
        title: 'Разобраться в номере',
        checked: false,
        active: false
      },
      {
        id: 12,
        title: 'В лосинах. Оценить лосины. Визуально, тактильно, с разных сторон',
        checked: false,
        active: false
      },
      {
        id: 13,
        title: 'Животик',
        checked: false,
        active: false
      },
      {
        id: 14,
        title: 'Шахматы. Ты сидишь у меня на коленях',
        checked: false,
        active: false
      },
      {
        id: 15,
        title: 'Рассмотреть ножки. Пощупать со всех сторон',
        checked: false,
        active: false
      },
      {
        id: 16,
        title: 'Пофоткаться вместе',
        checked: false,
        active: false
      },
      {
        id: 17,
        title: 'Фотка попы во всей красе',
        checked: false,
        active: false
      },
      {
        id: 18,
        title: 'Погладить по головке и почесать за ушком',
        checked: false,
        active: false
      },
      {
        id: 19,
        title: 'В карты на желания',
        checked: false,
        active: false
      },
      {
        id: 20,
        title: 'Исполнить "Паразита"',
        checked: false,
        active: false
      },
      {
        id: 21,
        title: 'Запрыгиваешь на меня как во сне - в лифте, обхватив меня ножками',
        checked: false,
        active: false
      },
      {
        id: 22,
        title: 'Согреть ляжки ручками',
        checked: false,
        active: false
      },
      {
        id: 23,
        title: 'Согреть попу ручками',
        checked: false,
        active: false
      },
      {
        id: 24,
        title: 'Ложисься на животик, массаж всего подряд',
        checked: false,
        active: false
      },
      {
        id: 25,
        title: 'Смотреть в глазки 10 секунд',
        checked: false,
        active: false
      },
      {
        id: 26,
        title: 'Держать за попу и смотреть в глазки 10 секунд',
        checked: false,
        active: false
      },
      {
        id: 27,
        title: 'Лежать в обнимку и смотреть в глазки',
        checked: false,
        active: false
      },
      {
        id: 28,
        title: 'Щека об щеку при обниманиях',
        checked: false,
        active: false
      },
      {
        id: 29,
        title: 'Погладить по попе',
        checked: false,
        active: false
      },
      {
        id: 30,
        title: 'Пофоткать в лосинах. Фотограф говорит, как надо встать',
        checked: false,
        active: false
      },
      {
        id: 31,
        title: 'Послушать три песни на выбор',
        checked: false,
        active: false
      },
      {
        id: 32,
        title: 'Сказать что-нибудь на ушко',
        checked: false,
        active: false
      },
      {
        id: 33,
        title: 'Обнять без футболки',
        checked: false,
        active: false
      },
      {
        id: 34,
        title: 'Отжимания с разных сторон. И с добавлением подушки под попу',
        checked: false,
        active: false
      },
      {
        id: 35,
        title: 'Отжимания у стены. С разных сторон',
        checked: false,
        active: false
      },
      {
        id: 36,
        title: 'Карты на раздевание',
        checked: false,
        active: false
      },
      {
        id: 37,
        title: 'Танец попой "Восьмерка"',
        checked: false,
        active: false
      },
      {
        id: 38,
        title: 'Показываешь танец',
        checked: false,
        active: false
      },
      {
        id: 39,
        title: 'Танец вместе, когда крутишь попой, мои руки - понятно, что на попе',
        checked: false,
        active: false
      },
      {
        id: 40,
        title: 'Прижимаешь меня попой к стене и рисуешь восьмерку',
        checked: false,
        active: false
      },
      {
        id: 41,
        title: 'Ложишься на животик, я опускаюсь вниз, как при отжимании, и ты рисуешь попой восьмерку',
        checked: false,
        active: false
      },
      {
        id: 42,
        title: 'Прижимаю тебя к стене лицом и рисую единичку',
        checked: false,
        active: false
      },
      {
        id: 43,
        title: 'Ложишься на животик, я опускаюсь вниз, как при отжимании и рисую единичку',
        checked: false,
        active: false
      },
      {
        id: 44,
        title: 'Ложимся вместе под одеялом, обнимаемся, ты утыкаешься своим носиком мне в плечо, я глажу тебя по головке',
        checked: false,
        active: false
      },
      {
        id: 45,
        title: 'Танец попой под "Прототип"',
        checked: false,
        active: false
      },
      {
        id: 46,
        title: 'Рисуем в кроватке и у стены одновременно цифры: 1 и 8',
        checked: false,
        active: false
      },
      {
        id: 47,
        title: 'Обняться в кроватке и смотреть друг другу в глазки 10 минут',
        checked: false,
        active: false
      },
      {
        id: 48,
        title: 'Посмотреть вместе всякие фотки',
        checked: false,
        active: false
      },
      {
        id: 49,
        title: 'Ложишься попой вверх мне на колени. Правильный ответ - 2 поглаживания, неправильный - 1 поглаживание + 1 утешительное',
        checked: false,
        active: false
      },
      {
        id: 50,
        title: 'Порвать колготки',
        checked: false,
        active: false
      },
      {
        id: 51,
        title: 'Обнять нежно, прижать поближе к себе',
        checked: false,
        active: false
      },
      {
        id: 52,
        title: 'Обнять комочек сзади',
        checked: false,
        active: false
      },
      {
        id: 53,
        title: 'Обнять комочек спереди',
        checked: false,
        active: false
      },
      {
        id: 54,
        title: 'Обнять сзади, стоя',
        checked: false,
        active: false
      },
      {
        id: 55,
        title: 'Обнять спереди, лежа',
        checked: false,
        active: false
      },
      {
        id: 56,
        title: 'Обнять сзади, лежа',
        checked: false,
        active: false
      },
      {
        id: 57,
        title: 'Обнять и найти линию',
        checked: false,
        active: false
      },
      {
        id: 58,
        title: 'Погладить ножки в колготках',
        checked: false,
        active: false
      },
      {
        id: 59,
        title: 'Отжимания с комментариями "еще, еще"',
        checked: false,
        active: false
      },
      {
        id: 60,
        title: 'Гончарная',
        checked: false,
        active: false
      },
      {
        id: 61,
        title: 'Вместе прочитать сообщение',
        checked: false,
        active: false
      },
      {
        id: 62,
        title: 'Показать лямку',
        checked: false,
        active: false
      },
      {
        id: 63,
        title: 'Садимся как в пункте "Гончарная" и качаемся вверх-вниз',
        checked: false,
        active: false
      },
      {
        id: 64,
        title: 'Я стою около стены, а ты передо мною, вплотную, и наклоняешься завязать шнурочки',
        checked: false,
        active: false
      },
      {
        id: 65,
        title: 'Поцеловать в правую щечку, затем посмотреть в глазки, затем поцеловать в левую щечку, затем снова посмотреть в глазки и обнять',
        checked: false,
        active: false
      },
      {
        id: 66,
        title: 'Кошечка',
        checked: false,
        active: false
      },
      {
        id: 67,
        title: '90-60-90 - Произвести замеры',
        checked: false,
        active: false
      },
      {
        id: 68,
        title: 'На спинке. Ножки вместе. Медленно раздвигаю ножки',
        checked: false,
        active: false
      },
      {
        id: 69,
        title: 'Массаж попы',
        checked: false,
        active: false
      },
      {
        id: 70,
        title: 'Погладить по головке и руки в волосы',
        checked: false,
        active: false
      },
      {
        id: 71,
        title: 'Походка кошки',
        checked: false,
        active: false
      },
      {
        id: 72,
        title: 'Прижимаю к стенке, говорю, что обожаю, глажу по головке, и ты сползаешь по стеночке вниз',
        checked: false,
        active: false
      },
      {
        id: 73,
        title: 'Ложишься на животик, попой вверх, а я сверху',
        checked: false,
        active: false
      },
      {
        id: 74,
        title: 'Наклоняешься, типа моешь голову, а я заботливо держу тебя сзади, вплотную',
        checked: false,
        active: false
      },
      {
        id: 75,
        title: 'Ты не бреешь меня, но нежно и заботливо держишь меня вплотную в лосинах, прижавшись ко мне попкой',
        checked: false,
        active: false
      },
      {
        id: 76,
        title: 'Исполняешь 1/2',
        checked: false,
        active: false
      },
      {
        id: 77,
        title: 'Намочить колготки и порвать',
        checked: false,
        active: false
      },
      {
        id: 78,
        title: 'Объятия под "Мой рок-н-ролл"',
        checked: false,
        active: false
      },
      {
        id: 79,
        title: 'Объятия под "Молитва"',
        checked: false,
        active: false
      },
      {
        id: 80,
        title: 'Поднять платье',
        checked: false,
        active: false
      },
      {
        id: 81,
        title: 'Объятия в нижнем белье',
        checked: false,
        active: false
      },
      {
        id: 82,
        title: 'Одновременно пощупать зопки друг у друга',
        checked: false,
        active: false
      },
      {
        id: 83,
        title: 'Завязать мне глаза, а дальше - импровизация',
        checked: false,
        active: false
      },
      {
        id: 84,
        title: 'Завязать тебе глаза, а дальше - импровизация',
        checked: false,
        active: false
      },
      {
        id: 85,
        title: 'Записать совместное видео',
        checked: false,
        active: false
      },
      {
        id: 86,
        title: 'Искать "кнопку" на моем животике',
        checked: false,
        active: false
      },
      {
        id: 87,
        title: 'Ты берешь мои руки и ведешь ими по своим ножкам',
        checked: false,
        active: false
      },
      {
        id: 88,
        title: 'Мои руки на твоей попе, а твои руки - поверх моих',
        checked: false,
        active: false
      },
      {
        id: 89,
        title: 'Берешь мою руку и кладешь себе на коленку',
        checked: false,
        active: false
      },
      {
        id: 90,
        title: 'Берешь мою руку и кладешь себе на попу',
        checked: false,
        active: false
      },
      {
        id: 91,
        title: 'Кладешь свои руки мне на животик, а  свои руки кладу поверх твоих',
        checked: false,
        active: false
      },
      {
        id: 92,
        title: 'Расстегнуть одежду двумя руками',
        checked: false,
        active: false
      },
      {
        id: 93,
        title: 'Расстегнуть одежду правой рукой',
        checked: false,
        active: false
      },
      {
        id: 94,
        title: 'Расстегнуть одежду левой рукой',
        checked: false,
        active: false
      },
      {
        id: 95,
        title: 'Изобразить пьяного человека с заигрываниями',
        checked: false,
        active: false
      },
      {
        id: 96,
        title: 'Расстереть ножки изнутри, ляжки, массаж. Не только изнутри',
        checked: false,
        active: false
      },
      {
        id: 97,
        title: 'Кладешь свою руку мне на коленку и поднимаешься вверх',
        checked: false,
        active: false
      },
      {
        id: 98,
        title: 'Глажу по ножке к попе, по попе, обратно к ножке, по ножке',
        checked: false,
        active: false
      },
      {
        id: 99,
        title: 'Подойти сзади, обнять нежно за талию, прижать к себе. Затем плавно обнять за животик, поцеловать в шейку и прошептать на ушко',
        checked: false,
        active: false
      },
      {
        id: 100,
        title: 'Обняться в футболке и в футболке без нижнего белья',
        checked: false,
        active: false
      },
      {
        id: 101,
        title: 'Ты на столе, я подхожу и обнимаю, а ты обхватываешь ножками',
        checked: false,
        active: false
      },
      {
        id: 102,
        title: 'Сказать разное на ушко',
        checked: false,
        active: false
      },
      {
        id: 103,
        title: 'Фотка щечка к щечке',
        checked: false,
        active: false
      },
      {
        id: 104,
        title: 'Стоим рядом, ты ко мне спиной. Я присаживаюсь на кровать, а ты разворачиваешься',
        checked: false,
        active: false
      },
      {
        id: 105,
        title: 'Становишься на колени и локти, а я сзади держу, вплотную',
        checked: false,
        active: false
      },
      {
        id: 106,
        title: 'Ты на спинке, я сверху, ножки в сторону, единичка об нее',
        checked: false,
        active: false
      },
      {
        id: 107,
        title: 'Я лежу на спине, а ты отжимаешься надо мною',
        checked: false,
        active: false
      },
      {
        id: 108,
        title: 'Заколоть мне невидимку в волосы',
        checked: false,
        active: false
      },
      {
        id: 109,
        title: 'Сказать мне на ушко: Я тебя обожаю, тигренок, и душу твою люблю',
        checked: false,
        active: false
      },
      {
        id: 110,
        title: 'Обнять покрепче и прижать к себе',
        checked: false,
        active: false
      },
      {
        id: 111,
        title: 'Ты соблазняешь меня по-разному в течение 10 минут',
        checked: false,
        active: false
      },
      {
        id: 112,
        title: 'Ты проводишь ручкой мне по животику и по груди',
        checked: false,
        active: false
      },
      {
        id: 113,
        title: 'Ручками двигаюсь по колготочкам вверх, по спирали',
        checked: false,
        active: false
      },
      {
        id: 114,
        title: 'Гладим по головкам друг другу',
        checked: false,
        active: false
      },
      {
        id: 115,
        title: 'Ножки вместе и вверх в лосинах, а я держу их так, вплотную',
        checked: false,
        active: false
      },
      {
        id: 116,
        title: 'Взять одной рукой за попу, а второй - за цель',
        checked: false,
        active: false
      },
      {
        id: 117,
        title: 'Погладить одной рукой по попе, а второй - по цели',
        checked: false,
        active: false
      },
      {
        id: 118,
        title: 'Погладить по цели, пока не кончишь',
        checked: false,
        active: false
      },
      {
        id: 119,
        title: 'Тереться об попу, пока не кончу',
        checked: false,
        active: false
      },
      {
        id: 120,
        title: 'Тереться об цель, пока не кончу',
        checked: false,
        active: false
      },
      {
        id: 121,
        title: 'Гладить друг другу, пока не кончим',
        checked: false,
        active: false
      },
      {
        id: 122,
        title: 'Тереться друг об друга, пока не кончим',
        checked: false,
        active: false
      },
      {
        id: 123,
        title: 'Я лежу на спинке. Ты кладешь ручку на животик, а затем вниз - на него',
        checked: false,
        active: false
      },
      {
        id: 124,
        title: 'Ты лежишь на спинке. Я кладу ручку на животик, а затем вниз - на нее',
        checked: false,
        active: false
      },
      {
        id: 125,
        title: 'Войти',
        checked: false,
        active: false
      },
      {
        id: 126,
        title: 'Укусить друг друга за ляшки',
        checked: false,
        active: false
      }
    ]
  },
  mutations: {
    SET_LISTS(state, load) {
      state.lists = load
    },
    SET_CURRENT_LIST(state, load) {
      state.currentList = load
    }
    // BACKUP(state, load) {
    //   state.points = load
    // },
    // SET_POINTS(state, load) {
    //   state.points = load
    // },
    // SET_CURRENT_TAB(state, load) {
    //   state.currentTab = load
    // }
  },
  actions: {

  },
  getters: {
    GET_LISTS(state) {
      return state.lists
    },
    GET_CURRENT_LIST(state) {
      return state.currentList
    },
    GET_COLORS(state) {
      return state.colors
    }
    // GET_POINTS(state) {
    //   return state.points
    // },
    // GET_BACKUP(state) {
    //   return state.backupPoints
    // },
    // GET_CURRENT_TAB(state) {
    //   return state.currentTab
    // }
  }
}