<template>
  <svg width="822" height="120" viewBox="0 0 822 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M86.3428 120H69.3262V60.4834C69.3262 54.6175 68.2194 49.1113 66.0059 43.9648C63.7923 38.8184 60.7487 34.3359 56.875 30.5176C53.0566 26.6439 48.5465 23.6003 43.3447 21.3867C38.1982 19.1178 32.6921 17.9834 26.8262 17.9834H17.6953V120H0.678711V0.966797H26.8262C32.2493 0.966797 37.4512 1.6862 42.4316 3.125C47.4674 4.50846 52.1712 6.47298 56.543 9.01855C60.9147 11.5641 64.8991 14.6077 68.4961 18.1494C72.0931 21.6911 75.2197 25.6201 77.876 29.9365C80.4769 25.6201 83.5758 21.6911 87.1729 18.1494C90.7699 14.6077 94.7542 11.5641 99.126 9.01855C103.498 6.47298 108.174 4.50846 113.154 3.125C118.19 1.6862 123.42 0.966797 128.843 0.966797H155.073V120H138.057V17.9834H128.843C122.977 17.9834 117.471 19.1178 112.324 21.3867C107.178 23.6003 102.668 26.6439 98.7939 30.5176C94.9756 34.3359 91.932 38.8184 89.6631 43.9648C87.4495 49.1113 86.3428 54.6175 86.3428 60.4834V120Z" fill="#ED143D"/>
    <path d="M325.737 60.4834C325.737 68.7288 324.437 76.4762 321.836 83.7256C319.235 90.9749 315.527 97.2835 310.713 102.651C305.954 108.019 300.226 112.253 293.53 115.352C286.834 118.451 279.391 120 271.201 120H231.606C223.361 120 215.863 118.451 209.111 115.352C202.36 112.253 196.577 108.019 191.763 102.651C187.004 97.2835 183.324 90.9749 180.723 83.7256C178.122 76.4762 176.821 68.7288 176.821 60.4834C176.821 52.2933 178.122 44.6012 180.723 37.4072C183.324 30.1579 187.004 23.8493 191.763 18.4814C196.577 13.0583 202.36 8.7972 209.111 5.69824C215.863 2.54395 223.361 0.966797 231.606 0.966797H271.201C279.391 0.966797 286.834 2.54395 293.53 5.69824C300.226 8.7972 305.954 13.0583 310.713 18.4814C315.527 23.8493 319.235 30.1579 321.836 37.4072C324.437 44.6012 325.737 52.2933 325.737 60.4834ZM308.721 60.4834C308.721 54.6175 307.863 49.1113 306.147 43.9648C304.432 38.8184 301.942 34.3359 298.677 30.5176C295.467 26.6439 291.538 23.6003 286.89 21.3867C282.297 19.1178 277.067 17.9834 271.201 17.9834H231.606C225.741 17.9834 220.456 19.1178 215.752 21.3867C211.104 23.6003 207.147 26.6439 203.882 30.5176C200.672 34.3359 198.182 38.8184 196.411 43.9648C194.696 49.1113 193.838 54.6175 193.838 60.4834C193.838 66.3493 194.696 71.8831 196.411 77.085C198.182 82.2314 200.672 86.7415 203.882 90.6152C207.147 94.4336 211.104 97.4495 215.752 99.6631C220.456 101.877 225.741 102.983 231.606 102.983H271.201C277.067 102.983 282.297 101.877 286.89 99.6631C291.538 97.4495 295.467 94.4336 298.677 90.6152C301.942 86.7415 304.432 82.2314 306.147 77.085C307.863 71.8831 308.721 66.3493 308.721 60.4834Z" fill="#ED143D"/>
    <path d="M468.013 60.4834C468.013 66.0173 467.293 71.3298 465.854 76.4209C464.471 81.4567 462.479 86.1882 459.878 90.6152C457.332 94.987 454.261 98.999 450.664 102.651C447.067 106.248 443.055 109.347 438.628 111.948C434.256 114.494 429.525 116.486 424.434 117.925C419.342 119.308 414.058 120 408.579 120H338.022V102.983H408.579C414.445 102.983 419.951 101.877 425.098 99.6631C430.244 97.4495 434.727 94.4336 438.545 90.6152C442.419 86.7415 445.462 82.2314 447.676 77.085C449.945 71.8831 451.079 66.3493 451.079 60.4834V17.9834H338.022V0.966797H468.013V60.4834Z" fill="#ED143D"/>
    <path d="M511.592 120H494.575V0.966797H511.592V120Z" fill="#ED143D"/>
    <path d="M604.81 120H587.793V17.9834H527.612V0.966797H664.99V17.9834H604.81V120Z" fill="#ED143D"/>
    <path d="M821.128 60.4834C821.128 68.7288 819.827 76.4762 817.227 83.7256C814.626 90.9749 810.918 97.2835 806.104 102.651C801.344 108.019 795.617 112.253 788.921 115.352C782.225 118.451 774.782 120 766.592 120H726.997C718.752 120 711.253 118.451 704.502 115.352C697.751 112.253 691.968 108.019 687.153 102.651C682.394 97.2835 678.714 90.9749 676.113 83.7256C673.512 76.4762 672.212 68.7288 672.212 60.4834C672.212 52.2933 673.512 44.6012 676.113 37.4072C678.714 30.1579 682.394 23.8493 687.153 18.4814C691.968 13.0583 697.751 8.7972 704.502 5.69824C711.253 2.54395 718.752 0.966797 726.997 0.966797H766.592C774.782 0.966797 782.225 2.54395 788.921 5.69824C795.617 8.7972 801.344 13.0583 806.104 18.4814C810.918 23.8493 814.626 30.1579 817.227 37.4072C819.827 44.6012 821.128 52.2933 821.128 60.4834ZM804.111 60.4834C804.111 54.6175 803.254 49.1113 801.538 43.9648C799.823 38.8184 797.332 34.3359 794.067 30.5176C790.858 26.6439 786.929 23.6003 782.28 21.3867C777.687 19.1178 772.458 17.9834 766.592 17.9834H726.997C721.131 17.9834 715.846 19.1178 711.143 21.3867C706.494 23.6003 702.537 26.6439 699.272 30.5176C696.063 34.3359 693.573 38.8184 691.802 43.9648C690.086 49.1113 689.229 54.6175 689.229 60.4834C689.229 66.3493 690.086 71.8831 691.802 77.085C693.573 82.2314 696.063 86.7415 699.272 90.6152C702.537 94.4336 706.494 97.4495 711.143 99.6631C715.846 101.877 721.131 102.983 726.997 102.983H766.592C772.458 102.983 777.687 101.877 782.28 99.6631C786.929 97.4495 790.858 94.4336 794.067 90.6152C797.332 86.7415 799.823 82.2314 801.538 77.085C803.254 71.8831 804.111 66.3493 804.111 60.4834Z" fill="#ED143D"/>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="sass">

</style>