<template>
  <transition name="notification">
    <div class="notification" v-if="point" :class="point.mode">
      <i class="close-notification bi bi-x" @click="close"></i>
      <div class="title">Уведомление</div>
      <div class="cnt">
        {{ point.message }} <b>{{ point.id }}</b>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Notification',
  data: () => ({
    point: null,
    interval: null
  }),
  methods: {
    close() {
      this.point = null
      this.$root.$emit('showExit')
    }
  },
  mounted() {
    this.$root.$on('notification', load => {
      if (this.interval) clearTimeout(this.interval)
      this.point = load
      this.$root.$emit('hideExit')
      this.interval = window.setTimeout(() => {
        if(this.point) this.point = null
        this.$root.$emit('showExit')
        clearTimeout(this.interval)
      }, 4000)
    })
  }
}
</script>

<style lang="sass">
.notification
  width: 300px
  padding: 20px
  border-radius: 10px
  color: $mainLight
  position: fixed
  z-index: 10
  top: 10px
  right: 10px
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5)
  &.create
    background: rgba($create, .8)
  &.delete
    background: rgba($main, .8)
  &.save
    background: rgba($save, .8)
  .title
    margin-bottom: 10px
  .close-notification
    position: absolute
    top: 15px
    right: 15px
    cursor: pointer
.notification-enter-active, .notification-leave-active
  transition: all .5s
.notification-enter, .notification-leave-to
  right: -10px
  opacity: 0
</style>