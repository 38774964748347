<template>
  <button
    :disabled="disabled"
    @click="clickBtn"
    :class="{icon: icon, withShadow: shadow}"
  >
    {{ title }}
    <i :class="icon" v-if="!loading"></i>
    <div v-else class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      default: 'Кнопка'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    clickBtn() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="sass">
  button
    border: none
    border-radius: 3px
    color: $mainLight
    padding: 0 20px
    height: 34px
    text-align: center
    font-size: 15px
    cursor: pointer
    white-space: nowrap
    display: grid
    align-content: center
    background: rgba($main, 1)
    &.withShadow
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .5)
    &.icon
      grid-template-columns: repeat(2, min-content)
      grid-gap: 4px
      justify-content: center
      align-items: center
      i
        position: relative
        top: 2px
        font-size: 18px
    .spinner-border
      width: 18px
      height: 18px
      font-size: 8px
      color: $mainLight
    &:hover
      background: $mainHover
    &:active
      background: $mainActive
    &:disabled
      background: rgba($mainDisabled, 1)

  @media screen and (max-width: 375px)
    button
      padding: 0 10px
</style>