<template>
  <div class="pointsTabs">
<!--    <div class="pointsTabs_tab"-->
<!--      v-for="(tab, i) in tabs"-->
<!--      :key="i"-->
<!--      :class="{active: tab.active}"-->
<!--      :style="{background: tab.active ? tab.colorFull : tab.color}"-->
<!--      @click="tabClick(tab)"-->
<!--    >-->
<!--      {{ tab.title }}-->
<!--    </div>-->
    <div class="pointsTabs_tab"
      v-for="(tab, i) in lists"
      :key="i"
    >
      {{ tab.title }}
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";

export default {
  name: 'PointsTabs',
  data() {
    return {
      // tabs: [
      //   {title: 'Основные', color: 'rgba(9,158,204, .5)', colorFull: 'rgba(9,158,204, 1)', key: 'pointsMain', active: false},
      //   {title: 'Горячие', color: 'rgba(237,131,40, .5)', colorFull: 'rgba(237,131,40, 1)', key: 'pointsHot', active: false},
      //   {title: 'Нежные', color: 'rgba(211,70,253, .5)', colorFull: 'rgba(211,70,253, 1)', key: 'pointsTenderness', active: false}
      // ]
    }
  },
  computed: {
    ...mapGetters(['GET_LISTS', 'GET_CURRENT_LIST']),
    lists() {
      return this.GET_LISTS
    },
    currentList() {
      return this.GET_CURRENT_LIST
    }
  },
  methods: {
    // ...mapMutations(['SET_CURRENT_TAB']),
    tabClick(tab) {
      // this.$root.$emit('nameCollection', tab.key)
      // this.SET_CURRENT_TAB({title: tab.title, key: tab.key})
    }
  },
  mounted() {
    // this.tabs.forEach(tab => tab.key === this.currentTab.key ? tab.active = true : tab.active = false)
  }
}
</script>

<style lang="sass">
.pointsTabs
  display: grid
  width: 100%
  grid-template-columns: repeat(auto-fit, minmax(0, min-content))
  grid-gap: 10px
  &_tab
    //color: $mainLight
    color: rgba($grey, .5)
    padding: 8px 15px
    border-radius: 10px
    text-align: center
    cursor: pointer
    &.active
      color: $mainLight
</style>