<template>
  <div class="modal-bg"  v-if="isModal" @click.self="close">

    <div class="modal-window">

      <div class="modal-help"></div>

      <i class="close bi bi-x-circle" @click="close"></i>
      <div class="title">{{ title }}</div>

      <template v-if="obj.mode === 'delete'">
        <div class="content">{{obj.point.id}}. {{ obj.point.title }}</div>
        <div class="buttons">
          <v-button icon="bi bi-x-circle" title="Отменить" @click="close" />
          <v-button icon="bi bi-trash" title="Удалить" @click="deletePoint" />
        </div>
      </template>

      <template v-if="obj.mode === 'edit'">
        <v-textarea placeholder="Название" v-model="pointTitle" />
        <div class="buttons">
          <v-button icon="bi bi-x-circle" title="Отменить" @click="close" />
          <v-button icon="bi bi-save" title="Сохранить" @click="updatePoint" />
        </div>
      </template>

      <template v-if="obj.mode === 'create'">
        <v-textarea placeholder="Название" v-model="newTitle" />
        <div class="buttons">
          <v-button icon="bi bi-x-circle" title="Отменить" @click="close" />
          <v-button icon="bi bi-plus-circle" title="Создать" @click="createPoint" />
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import button from "./button";
import textarea from "./textarea";
import Textarea from "./textarea";
import axios from "axios";
import consts from "../../consts";
import backConsts from "../../../server/consts";
import {mapMutations, mapGetters} from 'vuex';
export default {
  name: 'Modal',
  data: () => ({
    pointTitle: null,
    isModal: false,
    obj: null,
    newTitle: ''
  }),
  computed: {
    ...mapGetters(['GET_POINTS', 'GET_CURRENT_TAB']),
    title() {
      if (this.obj.mode === 'delete') return 'Удалить пункт?'
      else if (this.obj.mode === 'edit') return 'Редактирование'
      else if (this.obj.mode === 'create') return 'Создать пункт'
      else return ''
    },
    points() {
      return this.GET_POINTS
    },
    currentTab() {
      return this.GET_CURRENT_TAB
    }
  },
  components: {
    Textarea,
    'v-button': button,
    'v-textarea': textarea
  },
  methods: {
    ...mapMutations(['SET_POINTS']),
    close() {
      this.newTitle = ''
      this.isModal = false
    },
    async createPoint() {
      let id
      let flag = false
      if (this.points.length === 0) id = 1
      else {
        flag = true
        this.points.forEach((point, index) => {
          if (this.points[index + 1] && flag) {
            if (this.points[index + 1].id - this.points[index].id > 1) {
              id = this.points[index].id + 1
              flag = false
            }
          } else {
            if (flag) id = this.points[index].id + 1
            flag = false
          }
        })
      }
      const load = {
        id,
        title: this.newTitle,
        checked: false,
        active: false,
        mode: 'create',
        message: 'Добавлен пункт под номером'
      }
      const res = await axios.post(`${consts.baseURL}/api/${this.currentTab.key}/add`, load, {params: {key: backConsts.key}})
      const items = await axios.get(`${consts.baseURL}/api/${this.currentTab.key}`, {params: {key: backConsts.key}})
      await this.SET_POINTS(items.data)
      await this.$root.$emit('notification', load)
      this.isModal = false
      this.newTitle = ""
      this.$root.$emit('addPoint')
    },
    async deletePoint() {
      const loadNotification = {
        id: this.obj.point.id,
        mode: 'delete',
        message: 'Удален пункт под номером'
      }
      await axios.delete(`${consts.baseURL}/api/${this.currentTab.key}/delete`, {data: {id: this.obj.point.id}, params: {key: backConsts.key}})
      const items = await axios.get(`${consts.baseURL}/api/${this.currentTab.key}`, {params: {key: backConsts.key}})
      await this.SET_POINTS(items.data)
      this.$root.$emit('removePoint')
      await this.$root.$emit('notification', loadNotification)
      this.isModal = false
    },
    async updatePoint() {
      const load = {
        id: this.obj.point.id,
        title: this.pointTitle,
      }
      const loadNotification = {
        id: this.obj.point.id,
        mode: 'save',
        message: 'Изменен пункт под номером'
      }
      await axios.put(`${consts.baseURL}/api/${this.currentTab.key}/update`, load, {params: {key: backConsts.key}})
      let items = await axios.get(`${consts.baseURL}/api/${this.currentTab.key}`, {params: {key: backConsts.key}})
      await this.SET_POINTS(items.data)
      this.$root.$emit('notification', loadNotification)
      this.isModal = false
    }
  },
  mounted() {
    this.$root.$on('show-modal', obj => {
      this.isModal = true
      this.obj = obj
      this.pointTitle = obj.point ? obj.point.title : false
    })
  }
}
</script>

<style lang="sass" scoped>
.modal-bg
  position: fixed
  z-index: 5
  top: 0
  right: 0
  bottom: 0
  left: 0
  background: rgba(0, 0, 0, .5)
  .modal-window
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding: 30px
    border-radius: 10px
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, .5)
    //background-size: cover
    background: url("../../assets/img/bg.jpg") no-repeat center center
    color: $grey
    z-index: 10
    width: 350px
    .modal-help
      position: fixed
      top: 0
      right: 0
      bottom: 0
      left: 0
      background: rgba(255, 255, 255, 0.5)
      border-radius: 10px
      z-index: -1
    .close
      position: absolute
      top: 10px
      right: 10px
      color: $grey
      cursor: pointer
    .title
      margin: 10px 0 20px
      text-align: center
    .content
      height: 100px
    textarea
      width: 100%
      height: 100px
      font-size: 16px
      border-radius: 10px
      &::placeholder
        font-size: 16px
        color: rgba($grey, .5)
    .buttons
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 20px
      margin: 5px 0 0

@media screen and (max-width: 400px)
  .modal-bg
    .modal-window
      width: 90%
      .buttons
        button.icon
          ::v-deep i
            display: none
</style>