<template>
  <div class="drum">
    <div class="drum_screen"
         :class="[itemsClass, isStart ? 'start' : '']"
    >
      <div class="drum_screen-item pic"
           v-for="(item, i) in countOfItems"
           :key="i"
      >
        <v-glass v-if="isStart || isEmpty(i)" />
        <span v-else>{{ loadDrum[i] }}</span>

      </div>
    </div>
    <div class="drum_stat">
      <div class="drum_stat-all">
        <div class="drum_stat-all_number">{{ points.length }}</div>
        <div class="drum_stat-all_text">Всего</div>
      </div>
      <div class="drum_stat-rest">
        <div class="drum_stat-rest_number">{{ restPoints }}</div>
        <div class="drum_stat-rest_text">Осталось</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import glassOfMojito from "../common/glassOfMojito";
export default {
  name: 'Drum',
  data() {
    return {
      countOfItems: 0,
      randId: 0,
      loadDrum: [true, true, true]
    }
  },
  components: {
    'v-glass': glassOfMojito
  },
  props: {
    isStart: {
      type: Boolean
    },
    restPoints: {
      type: Number
    }
  },
  computed: {
    ...mapGetters(['GET_LISTS', 'GET_CURRENT_LIST']),
    lists() {
      return this.GET_LISTS
    },
    currentList() {
      return this.GET_CURRENT_LIST
    },
    points() {
      return this.lists[this.currentList].points
    },
    itemsClass() {
      let res
      if(this.countOfItems === 1) res = 'one'
      if(this.countOfItems === 2) res = 'two'
      if(this.countOfItems === 3) res = 'three'
      return res
    }
  },
  methods: {
    isEmpty(i) {return this.countOfItems - this.randId.toString().length >= i + 1},
    getCountOfItems(number) {
      this.countOfItems = number.toString().length
    },
    setLoadDrum(randId) {
      const arrId = randId.toString().split('')
      if(this.countOfItems === 3) {
        if(arrId.length === 1) {
          this.loadDrum[0] = true
          this.loadDrum[1] = true
          this.loadDrum[2] = arrId[0]
        } else if(arrId.length === 2) {
          this.loadDrum[0] = true
          this.loadDrum[1] = arrId[0]
          this.loadDrum[2] = arrId[1]
        } else if(arrId.length === 3) {
          this.loadDrum[0] = arrId[0]
          this.loadDrum[1] = arrId[1]
          this.loadDrum[2] = arrId[2]
        }
      } else if(this.countOfItems === 2) {
        if(arrId.length === 1) {
          this.loadDrum[0] = true
          this.loadDrum[1] = arrId[0]
        } else if(arrId.length === 2) {
          this.loadDrum[0] = arrId[0]
          this.loadDrum[1] = arrId[1]
        }
      } else if(this.countOfItems === 1) {
        this.loadDrum[0] = arrId[0]
      }
    }
  },
  async mounted() {
    this.getCountOfItems(this.points.length)

    this.$root.$on('animationDrum', randId => {
      this.randId = randId
      this.setLoadDrum(randId)
    })
  }
}
</script>

<style lang="sass">
.drum
  display: grid
  grid-template-rows: repeat(2, min-content)
  &_screen
    display: grid
    font-weight: bold
    margin-bottom: 15px
    grid-gap: 8px
    &.one
      grid-template-columns: min-content
      justify-content: center
    &.two
      grid-template-columns: repeat(2, min-content)
    &.three
      grid-template-columns: repeat(3, min-content)
    &-item
      width: 90px
      height: 120px
      background: $save
      color: $mainLight
      display: grid
      justify-content: center
      align-items: center
      font-size: 64px
      border-radius: 10px
      box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .5)
      position: relative
      span
        text-shadow: 2px 2px 2px rgba(0, 0, 0, .5)
      &.pic
        .mojito
          width: 60px
          height: auto
  &_stat
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 4px
    &-all
      &_number
        font-weight: bold
      &_text
        font-size: 14px
    &-rest
      text-align: right
      &_number
        font-weight: bold
      &_text
        font-size: 14px
</style>