<template>
  <div class="mobile-menu" :class="{show: isShow}">

    <div class="bg"></div>
    <div class="help"></div>

    <div class="top indent">
      <i class="close bi bi-x" @click="close"></i>
      <v-logo class="title" @click.native="toHome" />
    </div>

    <div class="links mobile">
      <router-link
        v-for="(link, index) in links"
        :key="index"
        :to="{name: link.route}"
        exact
      >
        <span @click="close">{{ link.name }}</span>
      </router-link>
    </div>

  </div>
</template>

<script>
import logo from "./logo";
export default {
  name: 'MobileMenu',
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    links: {
      type: Array,
      required: true
    }
  },
  components: {
    'v-logo': logo
  },
  methods: {
    close() {
      this.$emit('closeMobileMenu')
    },
    toHome() {
      if(this.$route.path !== '/') this.$router.push({name: 'Home'})
      this.close()
    }
  }
}
</script>

<style lang="sass" scoped>
.mobile-menu
  position: fixed
  top: 0
  //right: 100%
  right: 0
  bottom: 0
  //left: -100%
  left: 0
  z-index: 20
  background: grey
  overflow: hidden
  transition: all linear .4s
  padding: 20px 0
  display: none
  &.show
    //right: 0
    //left: 0
    display: block
  .bg
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: url("../../assets/img/bg2.jpg") no-repeat center center
    background-size: cover
    z-index: -1
  .help
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background: rgba(0, 0, 0, 0.5)
    z-index: -1
  .top
    position: relative
    .title
      //text-align: center
      //color: $main
      //font-size: 25px
      width: 100px
      height: auto
      margin: 0 auto 20px
      cursor: pointer
    .close
      font-size: 28px
      color: $mainLight
      position: absolute
      top: 50%
      left: 15px
      transform: translateY(-50%)
      cursor: pointer
      margin-top: 2px
      display: none
  .links.mobile
    display: grid
    justify-content: center
    grid-gap: 15px
    a
      color: rgba($mainLight, .7)
      text-decoration: none
      &.router-link-exact-active
        color: $main
        text-decoration: underline
</style>