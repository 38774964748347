const dbURL = 'mongodb+srv://stanley:1q2w3e4r@cluster0.dtgec.mongodb.net/mojito'
let baseURL

const isProduction = true

if(isProduction) baseURL = 'http://zxxc.ru:3000/mojito'
else baseURL = 'http://localhost:3000'

export default {
  dbURL,
  baseURL
}
