<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
import login from "@/layouts/login";
import inner from "@/layouts/inner";
import error from "@/layouts/error";
export default {
  components: {
    login,
    inner,
    error
  },
  computed: {
    layout() {
      return this.$route && this.$route.meta && this.$route.meta.layout ?
        this.$route.meta.layout : ''
    }
  }
}
</script>

<style lang="sass">
  #app
    max-width: 1400px
    margin: 0 auto
    width: 100%
</style>
